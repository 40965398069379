import ServiceApi from '../ServiceApi';
import UtilDate from "@/utils/UtilDate";
/** Сервис для работы с картой */
export default class ServiceCloudImport {
    /**
     * Название микросервиса для запросов
     * @private
     * @static
     * @type {String}
     */
    static _microserviceName = 'free';

    /**
     * Маршруты для запросов
     * @private
     * @static
     * @type {Object}
     */
    static _requestRouts = {
        list: '/cloud-import/list',
        preview: '/cloud-import/preview',
        detail: '/cloud-import/detail',
        clearSetting: 'cloud-import/clear-uploaded-time/',
        clearPlacements: 'cloud-import/clear-placements',
        clearSettingPlacements: 'cloud-import/clear-setting-placements',
        clearSettingInterval: 'cloud-import/clear-uploaded-interval',
        editFields: 'cloud-import/edit-setting-fields',
        previewColumnGroups: 'cloud-import/preview-column-groupings',
        editSetting: 'cloud-import/edit-setting',
        copySetting: 'cloud-import/copy-setting',
        validateSetting: 'cloud-import/validate-setting',
        settingLogs: 'cloud-import/logs',
        settingLog: 'cloud-import/log-sides',
    };

    /**
     * Список шаблонов
     * @public
     * @static
     * @async
     * @param {Number} page - строка поиска
     * @param {Object} queryParams - параметры запроса
     * @param {Function} actionAfter - событие, сработающее после запроса
     * @param {Function} actionError - событие, сработающее при ошибке
     */
    static async list(page = 1, queryParams = {}, actionAfter = () => {}, actionError = () => {}) {
        const reqParams = this._listBefore(page, queryParams);
        try {
            const data = await this._baseGetRequest(reqParams);
            const dataAfter = this._listAfter(data);
            actionAfter(dataAfter);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    static async previewColumnGroups(fileId, sheetIndex, addrs, actionAfter = () => {}, actionError = () => {}) {
        const reqParams = this._previewColumnGroupsBefore(fileId, sheetIndex, addrs);
        try {
            const data = await this._basePostRequest(reqParams);
            const dataAfter = this._previewColumnGroupsAfter(data);
            actionAfter(dataAfter);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    static _previewColumnGroupsAfter(data) {
        const {colors = [], values = []} = data
        return {
            colors,
            values: values.map((value) => {return {id: value, value}})
        };
    }

    static async preview(fileId, sheetIndex, page = 1, actionAfter = () => {}, actionError = () => {}, perPage = 20) {
        const reqParams = this._previewBefore(fileId, sheetIndex, page, perPage);
        try {
            const data = await this._baseGetRequest(reqParams);
            const dataAfter = this._previewAfter(page, data);
            actionAfter(dataAfter);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    static async detail(settingId, sheetIndex, actionAfter = () => {}, actionError = () => {}) {
        const reqParams = this._detailBefore(settingId, sheetIndex);
        try {
            const data = await this._baseGetRequest(reqParams);
            const dataAfter = this._detailAfter(data, sheetIndex);
            actionAfter(dataAfter);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    static async clearPlacements(supplierId, actionAfter = () => {}, actionError = () => {}) {
        const reqParams = this._clearPlacementsBefore(supplierId);
        try {
            await this._basePostRequest(reqParams);
            actionAfter();
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    static async clearSettingPlacements(settingId, actionAfter = () => {}, actionError = () => {}) {
        const reqParams = this._clearSettingPlacementsBefore(settingId);
        try {
            await this._basePostRequest(reqParams);
            actionAfter();
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    static _clearPlacementsBefore(supplierId) {
        return {
            routeKey: 'clearPlacements',
            reqQuery: `/${supplierId}`
        };
    }

    static _clearSettingPlacementsBefore(settingId) {
        return {
            routeKey: 'clearSettingPlacements',
            reqQuery: `/${settingId}`
        };
    }

    static async getFields(actionAfter = () => {}, actionError = () => {}) {
        try {
            const data = await this._baseGetRequest({routeKey: 'editFields'});
            const dataAfter = this._getFieldsAfter(data);
            actionAfter(dataAfter);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    static _getFieldsAfter(response) {
        const {fields = {}, statuses = {}, side_turns: sideTurns = []} = response;
        let resultFields = [];
        let resultStatuses = [];
        let resultSideTurns = [];
        Object.keys(fields).forEach((key) => {
            resultFields.push({
                id: key,
                value: fields[key]
            })
        })

        Object.keys(statuses).forEach((key) => {
            resultStatuses.push({
                id: key,
                value: statuses[key]
            })
        })

        Object.keys(sideTurns).forEach((key) => {
            resultSideTurns.push({
                id: key,
                value: sideTurns[key]
            })
        })

        return {fields: resultFields, statuses: resultStatuses, sideTurns: resultSideTurns};
    }

    static _detailAfter(settingData, sheetIndex) {
        const {
            sheet_statuses: sheetStatuses = [],
            type = "FILE",
            lastFile = {},
            name = "",
            path = "",
            created_at: createdAt = "",
            updated_at: updatedAt = "",
            uploaded_at: uploadedAt = "",
        } = settingData;
        const {templates = []} = settingData?.data ?? {};

        const sheetTemplateIndex = templates.findIndex((template) => {
            const {sheet_index: templateSheetIndex = 0} = template;
            return Number(templateSheetIndex) === Number(sheetIndex);
        });

        let sheetTemplate = templates[sheetTemplateIndex] ?? {};

        let {
            status_values: statusValues = {},
            status_colors: statusColors = {},
            side_turn_values: sideTurnValues = {},
            period_statuses: periodStatuses = {},
            header: headerArray = [],
            city: citySelected = {},
            type: typeSelected = {},
            kind: kindSelected = {},
            format: formatSelected = {},
            light: lightSelected = {},

            print_price: printPriceSelected = null,
            print_price_with_tax: printPriceWithTaxSelected = null,
            install_price: installPriceSelected = null,
            install_price_with_tax: installPriceWithTaxSelected = null,
            additional_install_price: additionalInstallPriceSelected = null,
            additional_install_price_with_tax: additionalInstallPriceWithTaxSelected = null,
        } = sheetTemplate

        const header = headerArray.reduce((obj, item) => {
            const {addr = null, color = null, val = null} = item
            obj[addr] = {color, val}
            return obj
        }, {})

        if(Array.isArray(periodStatuses)){
            sheetTemplate.period_statuses = {};
        }

        if(Array.isArray(sideTurnValues)){
            sheetTemplate.side_turn_values = {};
        }

        if(Array.isArray(statusColors)){
            sheetTemplate.status_colors = {};
        }

        if(Array.isArray(statusValues)){
            sheetTemplate.status_values = {};
        }

        if(Array.isArray(citySelected)){
            sheetTemplate.city = {};
        }

        if(Array.isArray(typeSelected)){
            sheetTemplate.type = {};
        }

        if(Array.isArray(kindSelected)){
            sheetTemplate.kind = {};
        }

        if(Array.isArray(formatSelected)){
            sheetTemplate.format = {};
        }

        if(Array.isArray(lightSelected)){
            sheetTemplate.light = {};
        }

        if(printPriceSelected !== null){
            sheetTemplate.print_price = String(printPriceSelected);
        }

        if(printPriceWithTaxSelected !== null){
            sheetTemplate.print_price_with_tax = String(printPriceWithTaxSelected);
        }

        if(installPriceSelected !== null){
            sheetTemplate.install_price = String(installPriceSelected);
        }

        if(installPriceWithTaxSelected !== null){
            sheetTemplate.install_price_with_tax = String(installPriceWithTaxSelected);
        }

        if(additionalInstallPriceSelected !== null){
            sheetTemplate.additional_install_price = String(additionalInstallPriceSelected);
        }

        if(additionalInstallPriceWithTaxSelected !== null){
            sheetTemplate.additional_install_price_with_tax = String(additionalInstallPriceWithTaxSelected);
        }

        return {
            sheetTemplate,
            sheetStatuses,
            header,
            type,
            lastFile,
            name,
            path,
            createdAt,
            updatedAt,
            uploadedAt: uploadedAt ? UtilDate.getLocaleDateWithTime(uploadedAt) : ""
        };
    }

    static _previewAfter(page, data) {
        // console.log(data)
        const {items = [], pageCount = null, sheetCount = null} = data;

        let labels = {num: {
            prop: "num",
            label: "№",
            isShow: true
        }};
        let resultItems = [];
        items.forEach((item) => {
            const {data: itemData = {}} = item
            const {row = {}, row_num: rowNum = 0} = itemData;
            let adaptedRows = {
                num: rowNum + 1
            };
            Object.keys(row).forEach((key) => {
                adaptedRows[key] = {
                    val: row[key]['val'] ?? null,
                    color: row[key]['color'] ? ('#' + row[key]["color"]) :  'transparent',
                    addr: key
                };
                labels[key] = {
                    prop: key,
                    label: key,
                    isShow: true,
                    isSlot: true
                };
            })
            resultItems.push(adaptedRows);
        })

        return {
            columns: Object.values(labels),
            items: resultItems,
            pageCount,
            sheetCount
        };
    }

    static _previewColumnGroupsBefore(fileId, sheetIndex, addrs) {
        return {
            routeKey: 'previewColumnGroups',
            reqBody: {
                addrs,
                file_id: fileId,
                sheet_index: sheetIndex
            }
        };
    }

    static _previewBefore(fileId, sheetIndex, page, perPage) {
        return {
            routeKey: 'preview',
            reqQuery: `/${fileId}/${sheetIndex}?page=${page}&per-page=${perPage}`
        };
    }

    static _detailBefore(settingId, sheetIndex) {
        return {
            routeKey: 'detail',
            reqQuery: `/${settingId}?sheet_index=`+sheetIndex
        };
    }

    /**
     *
     * @param {Number} settingId
     * @param {Number} sheetIndex
     * @param {Function} actionAfter
     * @param {Function} actionError
     */
    static async clearSetting(settingId, sheetIndex = null ,actionAfter = () => {}, actionError = () => {}) {
        const reqParams = this._clearSettingBefore(settingId, sheetIndex);
        try {
            await this._basePostRequest(reqParams);
            actionAfter();
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    static async clearSettingInterval(from, to,actionAfter = () => {}, actionError = () => {}) {
        const reqParams = this._clearSettingIntervalBefore(from, to);
        try {
            await this._basePostRequest(reqParams);
            actionAfter();
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    /**
     * Метод для запроса
     * @private
     * @static
     * @async
     * @param {Object} reqParams - параметры запроса
     * @returns {Object|Error}
     */
    static async _basePostRequest(reqParams = {}) {
        const {reqBody = {}, reqConfig = {}, routeKey = '', reqQuery = ''} = reqParams;
        try {
            const route = reqQuery.length ? `${this._requestRouts[routeKey]}${reqQuery}` : this._requestRouts[routeKey];
            const {data = {}} = await ServiceApi.post(this._microserviceName, route, reqBody, reqConfig);
            const {data: dataRes = []} = data;
            return dataRes;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    static _afterError(error) {
        const {response = {}} = error;
        const {data: responseData = {}, status = -1} = response;
        if (status !== 400){
            return ["Что то пошло не так"];
        }

        const {data = {}} = responseData;

        let allMessages = [];
        Object.keys(data).forEach((key) => {
            allMessages = [...allMessages, ...data[key]];
        })

        return allMessages
    }

    /**
     * Метод для запроса
     * @private
     * @static
     * @async
     * @param {Object} reqParams - параметры запроса
     * @returns {Object|Error}
     */
    static async _baseGetRequest(reqParams = {}) {
        const {reqBody = {}, reqConfig = {}, routeKey = '', reqQuery = ''} = reqParams;
        try {
            const route = reqQuery.length ? `${this._requestRouts[routeKey]}${reqQuery}` : this._requestRouts[routeKey];
            const {data = {}} = await ServiceApi.get(this._microserviceName, route, reqBody, reqConfig);
            const {data: dataRes = []} = data;
            return dataRes;
        } catch (error) {
            console.log(error);
            throw new Error(error);
        }
    }

    static _clearSettingBefore(settingId, sheetIndex) {
        let query = String(settingId);
        if(sheetIndex !== null){
            query += "?sheet_index=" + String(sheetIndex);
        }
        return {
            routeKey: 'clearSetting',
            reqQuery: query,
        };
    }

    static _clearSettingIntervalBefore(from, to) {
        return {
            routeKey: 'clearSettingInterval',
            reqBody: {
                from: UtilDate.getUnixTimestamp(from),
                to: UtilDate.getUnixTimestamp(to)
            }
        };
    }

    /**
     * Получить параметры для запроса на поиск по карте
     * @private
     * @static
     * @param {Number} page - страница
     * @param {Object} queryParams - параметры запроса
     * @returns {Object}
     */
    static _listBefore(page = 1, queryParams = {}) {
        let reqQuery = "?per-page=20&page=" + page;
        Object.keys(queryParams).forEach((param) => {
            reqQuery += `&${param}=${queryParams[param]}`;
        })

        return {
            routeKey: 'list',
            reqQuery
        };
    }

    static _listAfter(data){
        const {settings = [], pageCount = null, items = []} = data;
        let columns = [...settings];
        columns.push({
            prop: 'buttons',
            label: '',
            isShow: true,
            isSlot: true
        })

        return {items, pageCount, columns};
    }

    static async editSetting(settingId, params, actionAfter = () => {}, actionError = () => {}) {
        const reqParams = this._editSettingBefore(settingId, params);
        try {
            const data = await this._basePostRequest(reqParams);
            const {sheet_index: sheetIndex = 0} = params;
            const dataAfter = this._detailAfter(data, sheetIndex);
            actionAfter(dataAfter);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    static _editSettingBefore(settingId, params) {
        return {
            routeKey: 'editSetting',
            reqBody: {
                setting_id: settingId,
                params
            }
        };
    }

    static _copySettingBefore(reqBody) {
        return {routeKey: 'copySetting', reqBody};
    }

    static async copySetting(copyParams, actionAfter = () => {}, actionError = () => {}) {
        const {child_sheet_index: sheetIndex} = copyParams
        const reqParams = this._copySettingBefore(copyParams);
        try {
            const data = await this._basePostRequest(reqParams);
            const dataAfter = this._detailAfter(data, sheetIndex);
            actionAfter(dataAfter);
        } catch (error) {
            actionError(this._afterError(error));
        }
    }

    static async validateSetting(settingId, params, actionAfter = () => {}, actionError = () => {}) {
        try {
            const stepCount = 5;
            for (let step = 1; step <= stepCount; step++) {
                const reqParams = this._validateSettingBefore(settingId, params, step);
                const data = await this._basePostRequest(reqParams);
                actionAfter(data, stepCount === step);
            }
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    static _validateSettingBefore(settingId, params, step) {
        return {
            routeKey: 'validateSetting',
            reqBody: {
                setting_id: settingId,
                params
            },
            reqQuery: `?step=${step}`
        };
    }

    /**
     * Список логов шаблона
     * @public
     * @static
     * @async
     * @param {Number} settingId
     * @param {Number} page - строка поиска
     * @param {Object} queryParams - параметры запроса
     * @param {Function} actionAfter - событие, сработающее после запроса
     * @param {Function} actionError - событие, сработающее при ошибке
     */
    static async logList(settingId, page = 1, queryParams = {}, actionAfter = () => {}, actionError = () => {}) {
        const reqParams = this._logListBefore(settingId, page, queryParams);
        try {
            const data = await this._baseGetRequest(reqParams);
            const dataAfter = this._listAfter(data);
            actionAfter(dataAfter);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    /**
     * Получить параметры для запроса на поиск по карте
     * @private
     * @static
     * @param {Number} settingId
     * @param {Number} page - страница
     * @param {Object} queryParams - параметры запроса
     * @returns {Object}
     */
    static _logListBefore(settingId, page = 1, queryParams = {}) {
        let reqQuery = "/" + settingId + "?per-page=20&page=" + page;
        Object.keys(queryParams).forEach((param) => {
            reqQuery += `&${param}=${queryParams[param]}`;
        })

        return {
            routeKey: 'settingLogs',
            reqQuery
        };
    }

    /**
     * Список сторон загрузки
     * @public
     * @static
     * @async
     * @param {Number} taskId
     * @param {String} status
     * @param {Number} page - строка поиска
     * @param {Object} queryParams - параметры запроса
     * @param {Function} actionAfter - событие, сработающее после запроса
     * @param {Function} actionError - событие, сработающее при ошибке
     */
    static async logSideList(taskId, status, page = 1, queryParams = {}, actionAfter = () => {}, actionError = () => {}) {
        const reqParams = this._logSideListBefore(taskId, status, page, queryParams);
        try {
            const data = await this._baseGetRequest(reqParams);
            actionAfter(data);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    static _logSideListBefore(taskId, status, page = 1, queryParams = {}) {
        let reqQuery = "/" + taskId + "?status=" + status + "&per-page=100&page=" + page;
        Object.keys(queryParams).forEach((param) => {
            reqQuery += `&filter[${param}]=${queryParams[param]}`;
        })

        return {
            routeKey: 'settingLog',
            reqQuery
        };
    }
}
